import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import { useAuth } from "../util/auth";
import { useRouter } from "./../util/router";
import { CookieBanner } from "./../components/CookieBanner";
import NewsletterSection from "./../components/NewsletterSection";
import HeroSection from "./../components/HeroSection";
import StaticCards from "../components/StaticCards";

function IndexPage(props) {
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (auth.user) {
      router.push("/dashboard");
    }
  }, [auth]);

  return (
    <>
      <Meta />
      <CookieBanner />

      <HeroSection
        title="Discover Local Treasures & Exclusive Savings"
        subtitle="Support local businesses, enjoy exclusive discounts, and explore a marketplace where every purchase makes a difference. Shop with purpose, uplift your community, and find exceptional deals you won't get anywhere else!"
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />

      <h2 className="text-3xl font-extrabold text-gray-900 text-center sm:mt-10">
        How does it work?
      </h2>
      {/* Responsive Image Grid Section */}
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 m-10 p-5">
        <img
          src="/images/1.png"
          alt="Important Image 1"
          className="w-full rounded-lg object-cover shadow-md"
        />
        <img
          src="/images/2.png"
          alt="Important Image 2"
          className="w-full rounded-lg object-cover shadow-md"
        />
        <img
          src="/images/3.png"
          alt="Important Image 3"
          className="w-full rounded-lg object-cover shadow-md"
        />
        <img
          src="/images/4.png"
          alt="Important Image 4"
          className="w-full rounded-lg object-cover shadow-md"
        />
      </div>

      <div className="cursor-pointer">
        <StaticCards />
      </div>
      <NewsletterSection
        title="Subscribe to our newsletter"
        subtitle="Join us and receive the best curated news, freebies and resources directly to your inbox every week!"
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default IndexPage;
