import React from "react";
import BusinessCard from "./BusinessCard";
import Button from './Button';
import { Link } from "./../util/router";

function StaticCards() {
  const staticCards = [
    {
      name: "A2Z Windows",
      images: ["/images/a2zface.png"],
      discount_description: "10% off all products",
      short_description: "Best in class windows and doors",
    },
    {
      name: "Autoville",
      images: ["/images/autovilleface.png"],
      discount_description: "3% off all vehicles!",
      short_description: "The best deals on used motors",
    },

    {
      name: "Hij Armour",
      images: ["/images/hijamaface.png"],
      discount_description: "10% off everything",
      short_description: "Cupping and massage therapy",
    },
    {
      name: "Chip Co",
      images: ["/images/chipcoface.png"],
      discount_description: "10% off everything",
      short_description: "Crunchy, delicious chips in a variety of flavors.",
    },
    {
      name: "Sizzling Grillz",
      images: ["/images/sizzlingface.png"],
      discount_description: "10% off everything",
      short_description: "Grilled perfection with mouth-watering flavors.",
    },
    {
      name: "Minotaur Fight Store",
      images: ["/images/minotaurface.png"],
      discount_description: "10% off all products",
      short_description: "Fightwear",
    },
    // Remove duplicate entries if not needed
  ];

  return (
    <section className="pb-12 relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Title */}
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mt-5 mb-4">
          Discover top deals from featured businesses with Crescent Convenience
        </h2>
        <p className="text-lg text-gray-600 text-center mb-6">
          Explore and support amazing businesses in your community.
        </p>

        {/* Card Grid with Enhanced Gradient Overlay */}
        <div className="relative overflow-hidden">
          {/* Card Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 relative z-0">
            {staticCards.map((card, index) => (
              <BusinessCard
                key={index}
                card={card}
                handleOpenCardModal={() => { /* No modal for static cards */ }}
              />
            ))}
          </div>

          {/* Enhanced Gradient Overlay */}
          <div className="absolute bottom-0 left-0 right-0 h-80 bg-gradient-to-t from-white via-white to-transparent pointer-events-none z-10"></div>

          {/* Explore More Button positioned above the gradient */}
          <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2 z-20">
            <Button
              component={Link}
              to="/auth/signup"
              className="px-6 py-3 bg-customBeige font-medium rounded-md hover:bg-customBeige-dark transition duration-300"
            >
              Explore more!
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StaticCards;
