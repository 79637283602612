import React from "react";
import { calculateDistance } from "../util/location";

function BusinessCard({ card, handleOpenCardModal }) {
  const containedImages = [
    "Harrys MOTs",
    "Umbrella Plumbing and Heating",
    "SCREEDMEISTER",
    "Khoyaz",
  ];

  // Check if the business name is in the containedImages array
  const isCardContained = containedImages.includes(card.name);

  // Find the image that includes "face.png"
  const faceCardUrl = card?.images.find((imageUrl) =>
    imageUrl.includes("face.png")
  );

  return (
    <div
      onClick={() => handleOpenCardModal(card)}
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-xl transition-shadow duration-300"
    >
      {faceCardUrl ? (
        <img
          src={faceCardUrl}
          alt={card.name}
          className={`w-full h-48 ${
            isCardContained ? "object-contain" : "object-cover"
          }`}
        />
      ) : (
        <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
          <span className="text-gray-500">No Image Available</span>
        </div>
      )}

      <div className="p-6 text-center">
        {card?.discount_description && (
          <div className="flex justify-center items-center space-x-2 mb-2">
            {/* Distance Badge */}
            {card?.distance && !card?.online_only && (
              <div className="p-1 px-2 rounded-md text-sm font-medium bg-blue-500 text-white whitespace-nowrap">
                {`Distance: ${card?.distance} mi`}
              </div>
            )}

            {card?.online_only && (
              <div className="p-1 px-2 rounded-md text-sm font-medium bg-red-500 text-white whitespace-nowrap">
                Online only
              </div>
            )}

            {/* Discount Badge */}
            <div
              className={`p-1 px-2 rounded-md text-sm font-medium ${
                card?.discount_description === "Coming soon!"
                  ? "bg-gray-200 text-gray-700"
                  : "bg-customBeige"
              } whitespace-nowrap`}
            >
              {card?.discount_description}
            </div>
          </div>
        )}

        <h3 className="text-xl font-bold mb-2 text-gray-900">{card.name}</h3>
        <p className="text-gray-700">
          {card.short_description || card.description}
        </p>
      </div>
    </div>
  );
}

export default BusinessCard;
