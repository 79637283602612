import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { MdPhone, MdDirections } from "react-icons/md"; // Add directions icon
import { CiGlobe } from "react-icons/ci";

export const SocialMediaIcons = ({ address, contact, onlineOnly }) => {
  return (
    <div style={styles.iconsContainer}>
      <div style={styles.socialMediaIcons}>
        {contact?.facebook && (
          <a
            href={contact?.facebook}
            style={styles.iconItem}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF size={24} color="#3b5998" />
          </a>
        )}

        {contact?.twitter && (
          <a
            href={contact?.twitter}
            style={styles.iconItem}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size={24} color="#1da1f2" />
          </a>
        )}

        {contact?.instagram && (
          <a
            href={contact?.instagram}
            style={styles.iconItem}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={24} color="#e4405f" />
          </a>
        )}

        {contact?.phone && (
          <a href={`tel:${contact?.phone}`} style={styles.iconItem}>
            <MdPhone size={24} color="#34b7f1" />
          </a>
        )}

        {contact?.website && (
          <a
            href={contact?.website}
            style={styles.iconItem}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CiGlobe size={24} color="#ff5722" />
          </a>
        )}

        {address && !onlineOnly && (
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${address}`}
            style={styles.iconItem}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdDirections size={24} color="#4285f4" />
          </a>
        )}
      </div>
    </div>
  );
};

const styles = {
  iconsContainer: {
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "1.5%",
  },
  socialMediaIcons: {
    display: "flex",
    alignItems: "center",
  },
  iconItem: {
    margin: "0 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
