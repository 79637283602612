import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OpeningTimes from "./OpeningTimes";
import { SocialMediaIcons } from "./SocialMediaIcons";
import StarRating from "./StarRating";
import { ArrowDownOnSquareIcon, TagIcon } from "@heroicons/react/24/solid";

const CardModal = ({ cardContent, handleCloseModal }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isPromoVisible, setIsPromoVisible] = useState(false); // State for promocode visibility

  const businessLocation = {
    lat: cardContent.coordinates.latitude,
    lng: cardContent.coordinates.longitude,
  };

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const truncatedDescription = `${cardContent.description.slice(0, 100)}...`;

  const menuUrl =
    cardContent?.images.find((imageUrl) => imageUrl.includes("menu.pdf")) ||
    null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="relative bg-white w-full h-full md:max-w-lg rounded-lg shadow-lg overflow-hidden">
        <div className="flex flex-col h-full">
          {!cardContent.online_only && (
            <div className="w-full h-48 md:h-64 mb-4 sticky top-0 z-10">
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={businessLocation}
                  zoom={16}
                >
                  <Marker position={businessLocation} />
                </GoogleMap>
              </LoadScript>
            </div>
          )}

          <div className="p-4 flex-grow flex flex-col overflow-y-auto overflow-x-hidden">
            <h2 className="text-2xl text-black mb-2 font-bold">
              {cardContent.name}
            </h2>
            <p className="text-lg text-gray-700 mb-2">{cardContent.address}</p>

            <div className="flex justify-center mb-3">
              <StarRating rating={cardContent.rating} />
            </div>
            <SocialMediaIcons
              address={cardContent?.address}
              contact={cardContent.contact}
              onlineOnly={cardContent.online_only}
            />

            <div className="flex justify-center items-center space-x-2 mb-4">
              {cardContent?.distance && !cardContent.online_only && (
                <div className="p-1 px-2 rounded-md text-sm font-medium bg-blue-500 text-white whitespace-nowrap">
                  {`Distance: ${cardContent.distance} mi`}
                </div>
              )}

              {cardContent?.online_only && (
                <div className="p-1 px-2 rounded-md text-sm font-medium bg-red-500 text-white whitespace-nowrap">
                  Online only
                </div>
              )}

              {cardContent?.discount_description && (
                <div
                  className={`p-1 px-2 rounded-md text-sm font-medium whitespace-nowrap ${
                    cardContent.discount_description === "Coming soon!"
                      ? "bg-gray-200 text-gray-700"
                      : "bg-customBeige"
                  }`}
                >
                  {cardContent.discount_description}
                </div>
              )}
            </div>

            {/* Image carousel using React Slick */}
            <div className="mb-6">
              <Slider {...sliderSettings}>
                {cardContent?.images?.map((image, index) => (
                  <div key={index} className="relative w-full h-64 md:h-80">
                    <img
                      className="absolute inset-0 w-full h-full object-cover rounded-md"
                      src={image}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>

            {/* Business Description Section */}
            <div className="pt-3">
              <div className="border-t border-gray-200 my-4"></div>
              {menuUrl && (
                <button className="bg-customBeige justify-center w-full text-center text-black px-4 py-2 mb-4 rounded flex items-center text-sm md:text-base hover:bg-opacity-80">
                  <a
                    href={menuUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center w-full justify-center"
                    download
                  >
                    <ArrowDownOnSquareIcon className="mr-2 w-6 h-6" />
                    Menu
                  </a>
                </button>
              )}
              {/* Promocode Section */}
              {cardContent.online_code && (
                <div className="my-4 w-full">
                  <button
                    onClick={() => setIsPromoVisible(!isPromoVisible)}
                    className="w-full bg-blue-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-blue-600 flex items-center justify-center space-x-2"
                  >
                    <TagIcon className="w-5 h-5" />
                    <span>
                      {isPromoVisible
                        ? "Hide Online Promo Code"
                        : "Show Online Promo Code"}
                    </span>
                  </button>
                  {isPromoVisible && (
                    <div className="mt-2">
                      <div className="bg-gray-100 p-3 rounded-md text-center shadow-sm">
                        <p className="text-lg font-bold text-gray-800">
                          Your code: {cardContent.online_code}
                        </p>
                      </div>
                      {cardContent.contact?.website && (
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              cardContent.online_code
                            );
                            window.open(cardContent.contact.website, "_blank");
                          }}
                          className="w-full mt-3 bg-customBeige px-4 py-2 rounded text-sm md:text-base hover:bg-customBeige-200 flex items-center justify-center"
                        >
                          Copy code and visit store
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className="bg-gray-100 p-4 rounded-md shadow-sm">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  About {cardContent.title}
                </h3>
                <p className="text-base text-gray-600 mb-4 leading-relaxed">
                  {isDescriptionExpanded
                    ? cardContent.description
                    : cardContent.description.length > 100
                    ? truncatedDescription
                    : cardContent.description}
                </p>
                {cardContent.description.length > 100 && (
                  <button
                    onClick={() =>
                      setIsDescriptionExpanded(!isDescriptionExpanded)
                    }
                    className="text-blue-500 text-sm"
                  >
                    {isDescriptionExpanded ? "Show Less" : "Read More"}
                  </button>
                )}
              </div>
            </div>

            {/* Opening Times Section */}
            {!cardContent.online_only && (
              <div className="pt-3">
                <OpeningTimes hours={cardContent.opening_times} />
              </div>
            )}

            {/* Sticky button at the bottom of the modal */}
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-customBeige px-4 py-2 rounded text-sm md:text-base"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardModal;
